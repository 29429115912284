import React from "react";
import styled, { CreateStyled } from "@emotion/styled";
import { ThemeProvider } from "emotion-theming";

import { lightTheme, darkTheme, ITheme } from "./colors";

declare global {
  interface Window {
    __theme: "light" | "dark";
    __onThemeChange: () => any;
    __setPreferredTheme: (value: "light" | "dark") => any;
  }
}

interface IState {
  theme: "light" | "dark";
}

export class StyledThemeProvider extends React.Component {
  state: IState = {
    theme: "light",
  };

  componentDidMount() {
    this.setState({ theme: window.__theme });
    window.__onThemeChange = () => {
      this.setState({ theme: window.__theme });
    };
  }

  render() {
    return (
      <ThemeProvider
        theme={this.state.theme === "dark" ? darkTheme : lightTheme}
      >
        {this.props.children}
      </ThemeProvider>
    );
  }
}

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <StyledThemeProvider>{element}</StyledThemeProvider>
);

export default styled as CreateStyled<ITheme>;

export interface ITheme {
  name: "light" | "dark";

  textColorLight: string;
  textColor: string;

  backgroundColor: string;
  backgroundColorDark: string;

  accentColor: string;

  code: {
    background: string;
    comment: string;
    keyword: string;
    selector: string;
    function: string;
    operator: string;
    punctuation: string;
    property: string;
  };
}

export const lightTheme: ITheme = {
  name: "light",

  textColorLight: "#707070",
  textColor: "#252422",

  backgroundColor: "#FAFAFA",
  backgroundColorDark: "#2f3646",

  accentColor: "#edcd20",

  code: {
    background: "#f5f2f0",
    comment: "#5f707c",
    keyword: "#0076a8",
    selector: "#527a00",
    function: "d2284a",
    operator: "#8d6535",
    punctuation: "#6e6e6e",
    property: "#990055",
  },
};

export const darkTheme: ITheme = {
  name: "dark",

  textColorLight: "#cac2c2",
  textColor: "#d0d4db",

  backgroundColor: "#222939",
  backgroundColorDark: "#111725",

  accentColor: "#185F8B",

  code: {
    background: "#111725",
    comment: "#93A5AE",
    keyword: "#49AEDA",
    selector: "#84b915",
    function: "#DC4C69",
    operator: "#C49B68",
    punctuation: "#a3a2a2",
    property: "#C75C97",
  },
};
